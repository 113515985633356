import React from 'react';
import { images } from '../../constants';

import './About.css';

const About = () => (
  <div id="about">

    <div id='container'>
      <div id='grid-column'>
        <div className="about-title">
          <h1 className="about-title-text">Meet the Crew</h1>
        </div>
      </div>
      <div id='grid-column'>
        <hr id='hr-about'></hr>
      </div>


    <div id='grid-column'>
          <img className='crew-img' src={images.crew} alt='logo img' />
    </div>
    {/*     
    <div id="aboutus">
      <div className='parent'>
        <div className='child'>
          <div className="image-container">
            <img className='crew-img' src={images.crew} alt='logo img' />
          </div>
        </div>
      </div>
    </div> */}
    </div>
  </div>
);

export default About;
