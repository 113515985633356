import React from 'react';
import "@fontsource/merriweather";

import './Menu.css';

const goToMenu = () => { 
  window.open('https://streetfoodfinder.com/MookaosKitchen?tab=menu', '_blank'); // Opens in a new tab
}

const Menu = () => (
  <div id="menu">
    <div className='container'>
      <div className='grid-column'>
        <h1 className='text'>Take a look at our menu</h1>
      </div>
      <div className='grid-column'>
        <hr className='hr-menu'></hr>
      </div>

      <div className='grid-column'>
        <p className='text-sub'>So many delicious choices, you won't know where to start!</p>
      </div>
      <div className='grid-column'>
        <button className='menu-btn' onClick={goToMenu}>Full Menu</button>
      </div>
    </div>
  </div>
);

export default Menu;
