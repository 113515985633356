import logo from '../assets/logo.png';
import logo_mobile from '../assets/logo_mobile.png';
import background from '../assets/background.png';
import background_mobile from '../assets/background_mobile.png';
import crew from '../assets/crew.png';

// Assign the object to a variable
const assets = {
  logo,
  logo_mobile,
  background,
  background_mobile,
  crew,
};

// Export the variable as the default export
export default assets;