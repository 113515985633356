import React from 'react';

import { Home, Menu, About, Contact} from './container';
import { Navbar } from './components';
import './App.css';



const App = () => (
  <div>
    <Navbar />
    <Home />
    <Menu />
    <About />
    <Contact />
  </div>
);


export default App;
