import React from 'react';
import { images } from '../../constants';
import './Home.css';
import "@fontsource/merriweather";
import { TfiLocationPin } from "react-icons/tfi";

const Home = () => (
  <div id="home">
    <div className='background'>
      <div className='background-inner'>
        <img src={images.background} alt='header img' />
      </div>
      <div className='small-background'>
        <img src={images.logo} alt='logo img' />
      </div>
      <div className='small-background-smallscreen'>
        <img src={images.logo_mobile} alt='logo mobile img' />
      </div>
      <hr className='hr-header'></hr>
      <p className='hr-text'>Laotian/Vietnamese Fusion Cuisine made of the finest and freshest ingredients, made to order.</p>


      <div className='menu-route-button'> 
        <div className='location'><TfiLocationPin />{"  "} Nashville, TN</div>
      </div>
    </div>
  </div>
);

export default Home;
