import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { GiHamburgerMenu } from "react-icons/gi";

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [color, setColor] = useState(false);

  // Change navbar color on scroll
  const changeColor = () => {
    if (window.scrollY >= 40) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return () => window.removeEventListener('scroll', changeColor);
  }, []);

  return (
    <nav className={color ? 'app__navbar sticky__nav app__navbar_bg' : 'app__navbar sticky__nav'}>
      <div className={color ? 'app__navbar-logo app__navbar-logo-bg' : 'app__navbar-logo'}>
        <h2>
          <ScrollLink to="home">
            Mookao's Kitchen
          </ScrollLink>
        </h2>
      </div>
      <ul className='app__navbar-links'>
        <li className={color ? 'p__opensans-bg' : 'p__opensans'}>
          <ScrollLink to="menu" offset={-60} >
            Menu
          </ScrollLink>
        </li>
        <li className={color ? 'p__opensans-bg' : 'p__opensans'}>
          <ScrollLink to="about" offset={-60}>
            About
          </ScrollLink>
        </li>
        <li className={color ? 'p__opensans-bg' : 'p__opensans'}>
          <ScrollLink to="contact" offset={-60}>
            Contact
          </ScrollLink>
        </li>
      </ul>

      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu
          color="#000"
          fontSize={27}
          onClick={() => setToggleMenu(!toggleMenu)}
        />
        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
            <ul className='app__navbar-smallscreen_links'>
              <li className='p__opensans'>
                <ScrollLink to="menu" offset={-50} onClick={() => setToggleMenu(false)}>
                  Menu
                </ScrollLink>
              </li>
              <li className='p__opensans'>
                <ScrollLink to="about" offset={-50} onClick={() => setToggleMenu(false)}>
                  About
                </ScrollLink>
              </li>
              <li className='p__opensans'>
                <ScrollLink to="contact" offset={-50} onClick={() => setToggleMenu(false)}>
                  Contact
                </ScrollLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
