import React from 'react';
import './Contact.css';
import { FaPhone } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { GiFoodTruck } from "react-icons/gi";

const Contact = () => (

  <div id="contact">
    <div className='contact-background'>
      <div className="container-contact">
        <div className='grid-column-contact'>
          <h1 className="contact-title">Contact Us</h1>
        </div>
        <div className='grid-column-contact'>
          <hr className="hr-contact"></hr>
        </div>
        <div className='grid-column-contact'>
          <p className='contact-text'>If you have any questions or comments, please let us know! You're welcome to call, chat us on facebook, or follow us on social media!</p>
        </div>
        <div className='grid-column-contact'>
          <FaPhone className='faphone' />
        </div>
        <div className='grid-column-contact'>
          <a className='contact-text' href='tel:+16159876527'>(615) 987-6527</a>
        </div>
        <div className='grid-column-contact'>
          <a className='contact-text' href='mailto:mookaos@mailfence.com?subject=Inquiry&body=Hello%20there,%20I%20have%20a%20question.'>mookaos@mailfence.com</a>
          </div>
      </div>

      <div className='container-row'>
        <div className='grid-row-contact'>
          <a href="https://www.instagram.com/mookaoskitchen/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"><RiInstagramFill className='socials' />
            </a>
        </div>
        <div className='grid-row-contact'>
        <a href="https://www.facebook.com/profile.php?id=61552842537884"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link">
          <FaFacebook className='socials' />
          </a>
        </div>
        <div className='grid-row-contact'>
          <a href='https://www.bestfoodtrucks.com/hire-food-truck?truckId=9183' target="_blank"
            rel="noopener noreferrer"
            className="social-link">
        <GiFoodTruck className='socials' /></a>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
